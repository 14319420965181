import React from 'react'
import Navbar from '../components/navbar'
import Sectionone from '../components/productthree/secone'
import Sectiontwo from '../components/productthree/sectwo'

import Footer from '../components/footer'

export default function serviceone() {
  return (
    <div>
        <Navbar/>
        <Sectionone/>
        <Sectiontwo/>
        {/* <Sectionthree/> */}
        <Footer/>
    </div>
  )
}